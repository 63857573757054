#meet-the-team-banner{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    /* border: 1px solid green; */
    padding-top: 20px;
    height: 100vh;
    width: 100vw;
}

#meet-the-team-banner > #inner-container{
    color: #111111;
    /* height: 80vh; */
    max-width: 1100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 70%;
    margin-top: 150px;
    /* padding: 200px 0px; */
    /* border: 1px solid green; */
    /* height: 100vw; */

}

#meet-the-team-banner > #inner-container > #meet-the-team-text{
    /* border: 1px solid green; */
    width: 50%;
}

#meet-the-team-banner > #inner-container > #meet-the-team-text > h1{
    font-family: 'Montserrat', sans-serif;
    font-size: 5vw;
    font-weight: bold;
}

#meet-the-team-banner > #inner-container > #meet-the-team-text > h1 > #green{
    color: #00AF32;
}

#meet-the-team-banner > #inner-container > #meet-the-team-text > p{
    font-size: 16px;
    font-family: 'Comfortaa', cursive;
}

@media only screen and (min-width: 1800px){
    #meet-the-team-banner > #inner-container > #meet-the-team-text > h1{
        font-size: 3vw;
    }
}

@media only screen and (max-width: 760px){
    #meet-the-team-banner{
        margin-top: 100px;
    }
    #meet-the-team-banner > #inner-container > #meet-the-team-text{
        /* border: 1px solid green; */
        width: 90%;
        text-align: center;
    }
    #meet-the-team-banner > #inner-container > #meet-the-team-logo{
        width: 90%;
    }
    #meet-the-team-banner > #inner-container > #meet-the-team-logo > img{
        width: 100%;
    }
}