@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&family=Montserrat&display=swap');

#navbar-container{
    height: 100px;
    width: 100vw;
    display: flex;
    font-size: 14px;
    font-family: 'Comfortaa', cursive;
    background-color: white;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 40px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

#navbar-inner-container {
    /* border: 1px solid green; */
    width: 90%;
    max-width: 1400px;
    display: flex;
    justify-content: space-between;
}

#navbar-container > #navbar-inner-container > div{
    /* width: 50%; */
    /* height: 100%; */
    /* border: 1px solid yellow; */
    display: flex;
    align-items: center;
    padding-right: 10px;
}

#navigation-container{
    display: flex;
    justify-content: flex-end;
}

#logo-container{
    padding-left: 55px;
}


#navigation-container > a{
    text-decoration: none;
    color: #555555;
    padding: 5px 20px 5px 10px;
    cursor: pointer;
}

#navigation-container > #button{
    padding: 10px;
    color: #00AF32;
    border: 2px solid #00AF32;
    border-radius: 3px;
    font-family: 'Comfortaa', cursive;
    background-color: transparent;
}

#mobile-nav{
    background-color: #ffffff;
    width: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    padding-top: 20px;
    position: fixed;
    top: 0;
    bottom: 0;
}

#mobile-navigation-container{
    /* border: 1px solid green; */
    width: 90%;
    height: 80%;
}

#mobile-nav-inner-container{
    /* border: 1px solid blue; */
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#mobile-nav-inner-container > #button{
    display: block;
    padding: 10px;
    color: #00AF32;
    border: 2px solid #00AF32;
    border-radius: 3px;
    font-family: 'Comfortaa', cursive;
    background-color: transparent;
    /* border-top: 0.1px solid gray; */
}

#mobile-nav-inner-container > a{
    padding: 20px 0px;
    color: #555555;
    font-size: 20px;
    text-decoration: none;
    font-family: 'Comfortaa', cursive;
    border-top: 0.1px solid gray;
    width: 100%;
}

#mobile-nav-inner-container > a:nth-last-child(1){
    background-color: #00AF32;
    border-bottom: 0.1px solid gray;
}

/* Responsive */

@media only screen and (max-width: 830px){
    #navbar-container{
        top: 0px;
    }

    #navigation-container > a, #button{
        display: none; 
    }
    #logo-container{
        height: 100%;
        padding-left: 0px !important;
    }
    #navigation-container > i{
        color: #555555;
        font-size: xx-large;
    }

    #logo-container > img{
        height: 50px;
    }
}

@media only screen and (min-width: 830px){
    #navigation-container > i{
        display: none;
    }
}