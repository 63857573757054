.sw-store {
  /* max-width: 1140px; */
  margin: 0 auto;
}

.sw-store-main-body,
.sw-store--header--hero {
  max-width: 1140px;
  margin: 0 auto;
}

/* Header Hero */

.sw-store--header--hero {
  padding-left: 4.375em;
  height: 60vh;
  background-color: #00af32;
  display: flex;
  align-items: center;
}

.sw-store--header--hero h1 {
  width: 250px;
  font-weight: 800;
  font-size: 50px;
  line-height: 100%;
  text-transform: capitalize;
  color: #ffffff;
  opacity: 0.9;
}

/* Store List */
.sw-store--store-list {
  margin: 0 auto;
  margin-top: 90px;
  width: 90%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

/* Footer */
.sw-store-footer {
  display: flex;
  justify-content: center;
  font-size: 11px;
  letter-spacing: 0.05em;
  color: #555555;
}
