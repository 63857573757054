@font-face {
  font-family: 'HanziPen TC Regular';
  src: url('../../../../assets/fonts/hanzipen-tc-regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Grandstander ExtraBold';
  src: url('../../../../assets/fonts/Grandstander-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Avenir';
  src: url('../../../../assets/fonts/Avenir-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../../../../assets/fonts/AvenirNextCyr-Demi.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Roman';
  src: url('../../../../assets/fonts/Avenir-Roman.woff') format('woff');
}
* {
  padding: 0;
  margin: 0;
}
.first-hero {
  background-color: rgba(253, 209, 142, 0.5);
  /* background: radial-gradient(93.83% 65.23% at 50% 50%, rgba(253, 209, 142, 0.50) 59.9%, rgba(163, 98, 24, 0.50) 100%), #FFF; */
}
.first-hero-container {
  display: flex;
  width: 85%;
  margin: 0 7.5% 0 7.5%;
  justify-content: space-between;
}

.first-hero-content { 
    position: relative;
    width: 60%;
    max-width: 800px;
}

.virtual-reality-btn {
  display: flex; 
  gap: 5px;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  border-radius: 24px;
  background: rgba(81, 64, 38, 0.60);
  border: none;
  padding: 15px 23.35px 15px 18.84px;
  color: #FFF;
  font-family: 'Outfit';
  font-size: 0.9rem;
  font-weight: 700;
  line-height: normal;
  margin: 5rem 0 3rem 0;
}

.bead { 
  position: absolute;
  width: 25%;
  max-width: 150px;
  right: 8%;
  top: 14%; 
}

.bead img {
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.25);
  filter: blur(0.25px);

}

.first-hero-head {
  color: #514026;
  text-shadow: 
  .5px .5px 0px #514026, 
  -.5px -.5px 0px #514026,
  -.5px .5px 0px #514026,
  .5px -.5px 0px #514026;
  margin-top: 1rem;
  font-family: 'HanziPen TC Regular', sans-serif;
  font-size: 3.4rem;
  -webkit-text-stroke-width: 2;
  font-weight: 800;
  letter-spacing: 2px;
  line-height: 120%;
  }

.first-hero-paragraph {
  color: #000000;
  font-family: 'Avenir Roman', sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 2.2;
  margin: 1rem 0 2rem 0;
}

.hero-buttons a {
  font-family: "Grandstander ExtraBold", sans-serif;
    display: inline-block;
    background: rgba(81, 64, 38);
    color: #FFFFFF;
    padding: 15px 44.5px 15px 43.5px;
    text-shadow: 
    .5px .5px 0px #FFBD73, 
    -.5px -.5px 0px #FFBD73,
    -.5px .5px 0px #FFBD73,
    .5px -.5px 0px #FFBD73;
    font-size: 1rem;
    font-weight: 800;
    text-decoration: none;
  }

  .leaf { 
    position: absolute;
    width: 15%;
    max-width: 150px;
    bottom: 6%;
    right: 5.5%;
  }
  .leaf img {
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.25);
    filter: blur(0.25px);
  }

  .first-hero-image {
    position: relative;
    max-width: 400px;
    flex-direction: column;
    text-align: center;
    right: 0;
    width: 40%;
  }
  
  .leaves {
    width: 60%;
    position: absolute;
    right: 7%;
    top: 0;
    z-index: 1;
  }

  .ochemba-shield {
    width: 100%;
    max-width: 285.268px;
    height: 393.684px;
    margin: 9rem auto auto auto;
    display: block;
    filter: drop-shadow(0px 5px 12px rgba(0, 0, 0, 0.25));
  }

  .mask {
    width: 12%;
    background: transparent;
    position: absolute;
    right: 17%;
    top: 71%;
  }

.first-hero-text {
  font-family: 'Avenir Roman';
  font-size: 1rem;
  font-weight: 800;
  text-align: center;
}

@media (max-width: 1024px) {
  .first-hero-head {
    font-size: 2.8rem ;
  }
  .first-hero-paragraph {
    font-size: 0.85rem;
  }
}

@media (max-width: 962px) {
  
  .virtual-reality-btn {
    margin: 3.2rem 0 1.5rem 0;
  }
  .first-hero-head{
    font-size: 2.15rem;
  }
  .first-hero-paragraph{
    font-size: 0.68rem;
    line-height: 1.8;
  }
  /* .bead {
    right: 4.5%;
    top: 13%;
  } */
  .leaf {
    right: 1.5%;
    bottom: 15%;
  }
  .ochemba-shield{
    max-width: 200px;
    height: auto;
    margin: 5.8rem auto auto auto;
  }
}

@media (max-width: 912px) {
  .first-hero-head {
    font-size: 2.33rem;
  }
  .first-hero-paragraph {
    font-size: 0.73rem;
  }
}

@media (max-width: 853px) {
  .bead {
    right: 3.5%;
  }
}

@media (max-width: 820px) {
  .first-hero-container {
    width: 90%;
    margin: 0 5% 0 5%;
  }
  .first-hero-head {
    font-size: 2.17rem;
  }
  .first-hero-paragraph {
    font-size: 0.69rem;
  }
}
@media (max-width: 768px) {
  .first-hero-container {
    flex-direction: column; 
    width: 90%;
    margin: 0 5% 0 5%;
  }
  
  .first-hero-content,.first-hero-image {
    width: 100%;
    max-width: 100% ;
  }
  
  .first-hero-image {
    order: -1; 

  }
  .leaves {
    width: 30%;
    right: 27%;
    top: 0;
  }
  .ochemba-shield{
    margin: 3rem auto auto auto;
    max-width: 300px;
  }
  .mask {
    width: 8.5%;
    right: 25%;
  }

  .first-hero-text {
    font-size: 1rem;
  }

  .virtual-reality-btn {
    margin: 3rem 0 1.5rem 0;;
  }

  .first-hero-head {
    margin-top: 2rem;
    font-size: 3.71rem;
  }

  .bead {
    right: 11%;
    top:7% ;
  }

  .first-hero-paragraph {
    font-size: 1.1rem;
  }
  .hero-buttons a {
    padding: 15px 42px 15px 42px;
  }

  .leaf {
    bottom: 0;
    right: 2.8%;
  }

}

@media (max-width: 600px) {
  .first-hero-container {
    width: 90%;
    margin: 0 5% 0 5%;
  }
  .ochemba-shield {
    width: 100%;
  }
  .bead {
  right: 3%;
  top: 14%;
  }
  .first-hero-head {
    font-size: 2.6rem;
  }
  .first-hero-paragraph {
    font-size: .75rem;
  }
  .leaf {
    bottom: -15%;
    right: 11%;
  }
  .mask {
    top: 76%;
  }
}

@media (max-width: 540px) {
  .first-hero-head {
    font-size: 2.7rem;
  }
  .first-hero-paragraph {
    font-size: 0.85rem;
  }
}


@media (max-width: 480px) {
  .first-hero-head,.first-hero-image {
    width: 100%;}
  .first-hero-head {
    font-size: 2.32rem;
  }
  .first-hero-paragraph {
    font-size: 0.73rem;
  }
  .virtual-reality-btn{
    padding: 8px 12px;
    font-size: 0.7rem;
  }
  .hero-buttons a {
    padding: 14px 25px;
    font-size: 16px;
  }
  .leaves {
    width: 45%;
  }
  .mask {   
    width: 12%;
    right: 15%;
  }

}

@media (max-width: 430px) {
  .first-hero-head {
    font-size: 2.05rem;
  }

}

@media (max-width: 414px) {
  .first-hero-head {
    font-size: 2rem;
  }
  .first-hero-paragraph {
    font-size: 0.64rem;
  }
}

@media (max-width: 390px) {
.first-hero-container {
  flex-direction: column; 
}

.first-hero-head,.first-hero-image {
  width: 100%;
}

.first-hero-image {
  order: -1; 
}
.ochemba-shield{
  width: 100%;
}
.mask {
  width: 14%;
  top:75%;
  right: 8%;
}
.leaves {
  width: 50%;
  right: 12%;
}
.first-hero-content{
  width: 100%;
}

.first-hero-head {
 font-size: 1.84rem;
  }
.first-hero-paragraph {
  font-size: 0.61rem;
  line-height: 2;
}
.virtual-reality-btn{
  padding: 8px 10px;
  font-size: 0.6rem;
}
.hero-buttons a {
  padding: 12px 20px;
  font-size: 14px;
}

.first-hero-text {
  font-size: 0.8rem;
}
}


@media (max-width: 360px) {
  .first-hero-head {
    font-size: 1.71rem;
  }
  .first-hero-paragraph {
    font-size: 0.57rem;
  }
}

@media (max-width: 344px) {
  .first-hero-head {
    font-size: 1.63rem;
  }
  .first-hero-paragraph {
    font-size: 0.55rem;
  }
}

@media (max-width: 320px){
  .bead {
    top: 18%;
  }
  .first-hero-head {
    font-size: 1.5rem;
  }
  .first-hero-paragraph {
    font-size: 0.5rem;
  }
}
