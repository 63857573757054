#our-expertise-container{
    width: 100%;
    /* height: 100vh; */
    background-color: #111111;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 30px 0px;
}

#our-expertise-container > #inner-container{
/* border: 1px solid green; */
width: 80%;
max-width: 1100px;
height: 90%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}

#our-expertise-container > #inner-container > h1{
    font-size: 48px;
    font-family: 'Montserrat', sans-serif;
    color: #ffffff;
    font-weight: bold;
}

#our-expertise-container > #inner-container > p{
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    color: #ffffff;
    font-weight: medium;
    width: 90%;
    /* border: 1px solid gray; */
}

#our-expertise-container > #inner-container > div{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    /* justify-content: center; */
    /* border: 1px solid white; */
    width: 90%;
}

#our-expertise-container > #inner-container > #linkCon{
    width: 90%;
    /* padding: 20px; */
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* padding: 10px; */
}

#our-expertise-container > #inner-container > #linkCon > a{
    color: #00AF32;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    font-weight: medium;
    /* border: 1px solid green; */
    margin: 20px;
    text-decoration: none;
}


#our-expertise-container > #inner-container > div >ul{
    /* border: 1px solid green; */
    text-align: left;
    width: 400px;
}

#our-expertise-container > #inner-container > div > ul > li{
    font-family: 'Montserrat', sans-serif;
    color: #ffffff;
    /* font-weight: bold; */
    /* font-size: 18px; */
    list-style: none;
    padding: 20px 10px;
}


@media only screen and (max-width: 600px){
    #our-expertise-container > #inner-container{
        width: 85%;
    }
    #our-expertise-container > #inner-container > div >ul{
        text-align: center;
    }
}