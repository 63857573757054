.sw-store-navbar {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.9em 4.375em;
}

.sw-store-navbar--profile {
  display: flex;
  align-items: center;
}

/* Nav search section */

#search-store,
#select-category {
  font-family: Montserrat;
  outline-color: #00af32;
  height: 40px;
  border: 1px solid #00af32;
  box-sizing: border-box;
}

#search-store {
  width: 400px;
  padding: 0px 8px;
}

#search-store::placeholder {
  font-weight: 600;
}

#select-category {
  width: 177px;
  padding-left: 20px;
  border-radius: 0px 4px 4px 0px;
  margin-right: 0.9375em;
  border-left: none;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 20px top 50%;
  background-size: 12px;
}

/* Cart Badge Styles */

.store--cart {
  display: flex;
}

.item-count {
  padding-left: 9px;
  padding-right: 9px;
  border-radius: 9px;
}

#item-count {
  height: fit-content;
  font-size: 12px;
  margin-right: 40px;
  background: #00af32;
  color: #fff;
  padding: 0 4px;
  vertical-align: top;
  margin-left: -10px;
}
