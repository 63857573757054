.sw-store-item-container {
  margin: 20px;
  width: 220px;
  height: 450px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sw-store-item-container button {
  font-weight: bold;
  font-size: 1rem;
  padding: 12px;
  width: 100%;
  background: #00af32;
  border-radius: 15px;
  color: white;
  border: none;
}

.item-container--image-section {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100%;
  height: 300px;
  border-radius: 20px;
}

.item-container--image-section .item-image {
  width: 80%;
  height: 80%;
}

.item-container--description-section {
  padding-left: 20px;
}

.description-section--rating {
  display: flex;
  align-items: center;
}

.description-section--rating .rating-count {
  margin-left: 10px;
}

.description-section--item-info {
  margin-top: 5px;
  margin-bottom: 8px;
}

.description-section--item-info p {
  padding: 0px;
  margin: 0px;
  color: #222222;
}

.description-section__category {
  font-weight: normal;
  font-size: 10px;
  color: #9b9b9b;
  opacity: 0.8;
}
.description-section__item-name,
.description-section__item-price {
  font-weight: 600;
  font-size: 13px;
}
