.buyTicketsButton {
    font-size: 24px;
    padding: 10px 34px;
    width: 80%;
    margin-top: 24px;
    align-self: center;
    font-family: "Grandstander ExtraBold", sans-serif;
    font-weight: 800;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    border: 1px solid rgba(81, 64, 38, 1);
    background-color: rgba(81, 64, 38, 1);
    color: #ffffff;
    padding: 15px 44.5px 15px 43.5px;
    border-radius: 50px;
    align-items: center;
    display: flex;
    justify-content: center;
    text-shadow:  
                .5px .5px 0px #FFBD73, 
                -.5px -.5px 0px #FFBD73,
                -.5px .5px 0px #FFBD73,
                .5px -.5px 0px #FFBD73;

             
  }

  .spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #513006;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    align-content: center;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }