#homeCard{
    height: max-content;
    width: max-content;
    /* border: 1px solid green; */
    margin: 50px;
}

#homeCard >img{
    /* border: 1px solid black; */
    height: 400px;
}

#homeCard > h1, p{
    width: 100%;
    /* border: 1px solid green; */
    font-family: 'Montserrat', sans-serif;
    color: #333333;
    margin: 40px 0px;
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
    #homeCard >img{
        /* border: 1px solid black; */
        height: 330px;
    }
    #homeCard > h1, p{
        margin:  10px 0px;
    }
    #homeCard{
        margin: 30px 0px;
    }
}