@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');


.patners-container{
    width: 100vw;
    /* height: 45vh; */
    background-color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    color: #555555;
    font-weight: bold
}

.patners-header{
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0px 0px;
    text-align: center;
}

.patners-header h1{
    font-size: 24px;
    font-weight: 900;
    margin: 10px;
}

#patners-logo-container{
    width: 100%;
    height: 50%;
    /* border: 1px solid green; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

#patners-logo-container img{
    margin: 15px 60px;
    padding: 20px 0px;
}