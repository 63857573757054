/* @font-face {
  font-family: 'Grandstander ExtraBold';
  src: url('../../../../assets/fonts/Grandstander-ExtraBold.woff') format('woff');
}
@font-face {
  font-family: 'Grandstander Medium';
  src: url('../../../../assets/fonts/Grandstander-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Grandstander SemiBold';
  src: url('../../../../assets/fonts/Grandstander-SemiBold.ttf') format('truetype');
} */

* {
  margin: 0;
  padding: 0;
}

.receipt-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: #F2D9B7;
}

.receipt-container-left {
width: 30%;
border-right: 2px dotted #000000;
}

.receipt-header-mobile, .receipt-status-mobile {
  display: none;
}
.admit {
  font-family: 'Grandstander ExtraBold', sans-serif;
  font-size: 40px;
  text-align: center;
  padding: 10% 0 0 0;
  text-shadow:  
  .5px .5px 0px #FFFFFF, 
  -.5px -.5px 0px #FFFFFF,
  -.5px .5px 0px #FFFFFF,
  .5px -.5px 0px #FFFFFF;
}
.ticket-info {
  width: 70%;
  margin: auto;
  border: none;
}

.ticket-info p { 
  margin-bottom: 0;
  font-family: 'Grandstander Medium';
  background-color: #F2D9B7;
  color: #000000;
  font-size: 20px;
}

.admitted-name, .admitted-ticket, .admitted-reference {
  background-color: #514026;
  width: 48%;
  color: #FFFFFF;
  text-align: center;
  font-size: 20px;
  font-family: 'Grandstander Medium', sans-serif;
}

.qr-code {
  border: 1px solid black;
  width: 50%;
  margin: auto;
  height: auto;
  padding: 80px 12px;
}

.receipt-container-right {
width: 70%;
}

.receipt-header {
  display: flex;
  width: 90%;
  margin: auto;
  justify-content: space-between;
  /* margin-bottom: 20px; */
  padding: 2.5% 0 3.5% 0;
}

.receipt-header img {
  width: 5.5%;
}

.receipt-status {
  text-align: center;
}

.receipt-status p {
  font-size: 27px;
  margin-top: 0;
  font-family: 'Grandstander Medium', sans-serif;
}

.receipt-hero {
  text-align: center;
}
.OchembaImage {
  width: 22%;
}

.receipt-hero p {
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'Grandstander Medium', sans-serif;
  font-size: 40px;
}
.receipt-venue {
  position: relative;
  margin-top: 0;
  text-align: center;
}
.receipt-venue-absolute {
  background-color: #514026;
  width: 10%;
  padding: 3px 0;
  color: #FFFFFF;
  font-size: 20px;
  font-family: 'Grandstander ExtraBold', sans-serif;
  position: absolute;
  transform: rotate(-23deg);
  bottom: 72%;
  left: 19%;
}
.receipt-venue-main {
  font-family: 'Grandstander ExtraBold', sans-serif;
  font-size: 96px;
  color: #FFFFFF;
  text-shadow:  
                .5px .5px 0px #000000, 
                -.5px -.5px 0px #000000,
                -.5px .5px 0px #000000,
                .5px -.5px 0px #000000;
}

.print-button {
  background-color: #514026;
  width: 20%;
  border: none;
  border-radius: 12px;
  padding: 10px;
  color: #FFFFFF;
  font-size: 20px;
  font-family: 'Grandstander ExtraBold', sans-serif;
  text-align: center;
  margin-bottom: 3%;
}
@media (max-width: 1024px) {
  .receipt-header img {
    width: 9%;
  }
  .receipt-venue-absolute {
    left: 13%;
    width: 13%;
  }
  .print-button {
    width: 30%;
  }
}
@media (max-width: 962px) {
  .receipt-container-left {
    width: 40%;
  }
  .receipt-status {
    width: 55%;
    margin: auto;
    line-height: 1.4;
  }

.receipt-header img {
  width: 10%;
}
.OchembaImage {
  width: 35%;
}
.receipt-venue-main {
  font-size: 80px;
}
.receipt-venue-absolute {
  width: 18.5%;
  left: 5.5%;
}
.print-button {
  width: 40%;
}

}
@media (max-width: 768px) {
  .receipt-container-left {
    width: 50%;
  }
  .ticket-info {
    padding-left: 10%;
  }
  .receipt-header img {
    width: 10%;
  }

  .receipt-status {
    width: 65%;
    margin: auto;
    line-height: 1.4;
  }
  .OchembaImage {
    width: 30%;
  }

  .receipt-hero p {
    font-size: 30px;
  }
  .receipt-venue-main {
    font-size: 75px;
  }
  .receipt-venue-absolute {
    width: 17%;
    padding: 3px 0;
    font-size: 20px;
    bottom: 68%;
    transform: rotate(-23deg);
    left: 2%;
  }
  .print-button {
    width: 35%;
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .receipt-container {
    display: flex;
    flex-direction: column;
  }
  .receipt-container-left, .receipt-container-right {
    width: 100%;
  }
  .receipt-container-left {
    border-right: none;
  }
  .receipt-header, .receipt-status {
    display: none;
  }
  .admit {
    font-size: 45px;
  }
  .ticket-info {
    border: none;
    margin: auto;
    padding: 5% 0 5% 0;
  }
  .receipt-header-mobile {
    display: flex;
    width: 95%;
    margin: auto;
    justify-content: space-between;
  }
  .receipt-status-mobile {
    display: block;
    text-align: center;
  }
  .receipt-status-mobile p {
    font-family: 'Grandstander Medium', sans-serif;
    font-size: 20px;
  }
  .qr-code {
    margin-bottom: 5%;
  }
  .receipt-venue-main {
    margin-top: 5%;
  }

  .print-button {
    width: 30%;
  }
}

@media (max-width: 540px) {
  .receipt-venue-absolute {
    left: 8%;
    bottom: 84%;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .ticket-info-container {
    width: 80%;
    margin: auto;
  }
  .receipt-header-mobile img {
    width: 13%;
  }
  .receipt-status-mobile {
    width: 60%;
    margin: auto;
    line-height: 1.4;
  }
  .receipt-status-mobile p {
    font-size: 26px;
  }

  .receipt-venue-absolute {
    width: 17%;
    left: 4.3%;
    bottom: 82%;
  }
}

@media (max-width: 430px) {
  .receipt-venue-absolute {
    left: 0;
  }

  @media (max-width: 390px) {
    .receipt-header-mobile img {
    width: 11.5%;
    }
    .receipt-status-mobile {
      width: 75%;
    }
    .OchembaImage {
      width: 35%;
    }
    .receipt-venue-main {
      font-size: 60px;
    }
    .receipt-venue-absolute {
      left: 3.5%;
      font-size: 14px;
    } 
    .print-button {
      width: 35%;
    }
  }
}
