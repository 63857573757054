.home-banner-container{
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    /* border: 1px solid green; */
    
}

.home-banner-container > #inner-container {
    width: 100%;
    max-width: 1400px;
    /* border: 1px solid green; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 200px;
}

.home-banner-container > #inner-container > #hashtag{
    color: #00AF32;
    font-size: 36px;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    text-shadow: 0px 1px, 1px 0px, 1px 1px;
    /* border: 1px solid green; */

}

.home-banner-container > #inner-container > #activity{
    font-size: 72px;
    font-weight: 700;
    color: #111111;
    width: 65%;
    text-align: center;
    /* border: 1px solid green; */
    font-family: 'Montserrat', sans-serif;
    text-shadow: 0px 1px, 1px 0px, 1px 1px;
}

.home-banner-container > #inner-container  > p{
    font-family: 'Comfortaa', cursive;
    width: 597px;
    font-size: 16px;
    margin: 30px 0px;
    line-height: 150%;
    /* border: 1px solid green; */

}

.home-banner-container > #inner-container > span{
    font-family: 'Comfortaa', cursive;
    background-color: rgba(0, 175, 50, 0.1);
    width: 425px;
    font-weight: regular;
    color: #111111;
    padding: 10px;
    /* border: 1px solid green; */

}

/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
@media screen and (min-width: 601px) {
    .home-banner-container > #inner-container > #activity{
        font-size: 72px;
    }
  }
  
  /* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
  @media screen and (max-width: 600px) {

    .home-banner-container{
        height: 90vh;
    
    }
    
    .home-banner-container > #inner-container > #activity{
      font-size: 50px;
      width: 100%;
    }
    .home-banner-container > #inner-container  > p{
        width: 100%;
    }
    .home-banner-container > #inner-container > span{
        width: 100%;
    }
    .home-banner-container > #inner-container > #hashtag{
        width: 100%;
      font-size: 20px;

    }
  }