@font-face {
    font-family: 'Grandstander ExtraBold';
    src: url('../../../../../assets/fonts/Grandstander-ExtraBold.woff') format('woff');
  }
  @font-face {
    font-family: 'Grandstander Medium';
    src: url('../../../../../assets/fonts/Grandstander-Medium.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Grandstander SemiBold';
    src: url('../../../../../assets/fonts/Grandstander-SemiBold.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Grandstander Bold';
    src: url('../../../../../assets/fonts/Grandstander-Bold.ttf') format('truetype');
  }
* {
    margin: 0;
    padding: 0;
}
.login-section {
    background-color: #F2D9B7;
    width: 100%;
  }

  .login-container {
    width: 60%;
    margin: 0 20% 0 20%;
  }
  
  .logo-section {
    text-align: center;
    padding: 3%;
  }
  
  .logo {
    width: 20%;
    height: auto;
  }

  .login-header {
    margin-left: 15%;
    font-family: 'Grandstander Medium', sans-serif;
    font-size: 32px;
  }
  
  .form-section {
    width: 80%;
    margin: auto;
    margin-top: 5%;
    background-color: #514026;
    border-radius: 40px 40px 0 0;
  }
  
  .tabs {
    display: flex;
    width: 80%;
    margin-left: 8%;
    padding: 2.5% 3% 2% 3%;
    justify-content: space-between;
    margin-bottom: 20px;
    color: #fff;
  }

  .login-text, .register-text {
    font-size: 20.54px;
    cursor: pointer;
    font-family: 'Grandstander Medium', sans-serif;
    color: #fff;
    text-decoration: none;
  }
  
  .tabs .active {
    border-bottom: 2px solid #d4a574;
  }
  
  form { 
    width: 90%;
    margin-left: 4%;
  }
  .input-group {
    margin-bottom: 30px;
  }
  
  .input-group label {
    display: block;
    font-family: 'Grandstander Medium', sans-serif;
    font-size: 20.54px;
    margin-left: 5%;
    margin-bottom: 10px;
    color: #fff;
  }
  
  .input-group input {
    width: 90%;
    margin-left: 3%;
    background-color: #F2D9B7;
    padding: 14px 16px;
    border: 1px solid #D09148;
    border-radius: 25px;
    font-size: 14px;
  }
  
  .login-btn {
    width: 70%;
    margin-left: 15%;
    padding: 12px 14px;
    background-color: #D09148;
    font-family: 'Grandstander Bold', sans-serif;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 25px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .login-btn:hover {
    background-color: #c18c5d;
  }
  
  @media (max-width: 768px) {
    .login-container {
      width: 80%;
      margin: auto;
    }
    .logo {
      width: 35%;
    }

    .form-section {
      width: 100%;
    }

    .login-btn {
      padding: 10px 12px;
    }
  }
    @media (max-width: 600px) {
      .login-header {
        font-size: 25px;
        margin-left: 0;
      }
      form {
        margin-left: 1%;
      }
      .login-btn {
        font-size: 18px;
        margin-left: 19%;
      }
    }
    @media (max-width: 320px) {
      .login-header {
        font-size: 22px;
      }
      .login-text, .register-text, .input-group label, .login-btn {
        font-size: 16px;
      }
      .input-group input {
        padding: 10px 12px;
      }
    }