#who-we-are{
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 100vw; */
}

#who-we-are > h1{
    color: #333333;
    font-size: 48px;
}

#who-we-are  > p{
    font-family: 'Comfortaa', cursive;
    text-align: center;
    width: 600px;
    color: #111111;
    line-height: 150%;
}

#who-we-are-image-container{
    /* border: 1px solid green; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 50px 90px 50px;
    flex-wrap: wrap;
}

#who-we-are-image-container > div{
    display: flex;
    flex-direction: column;
    height: 450px;
    justify-content: space-between;
}

#who-we-are-image-container > #center{
    margin: 100px 50px 0px 50px 
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
    #who-we-are-image-container > div{
        display: flex;
        flex-direction: column;
        height: max-content;
        justify-content: space-evenly;
        padding: 0px;
        margin: 0px;
    }

    #who-we-are-image-container > div > img{
        padding: 10px;
    }
    #who-we-are  > p{
        width: 90%;
    }

    #who-we-are-image-container{
        padding: 0px;
    }

    #who-we-are-image-container > #center{
        margin: 0px 0px 0px 0px 
    }
    
}