@font-face {
  font-family: 'HanziPen TC Regular';
  src: url('../../../../assets/fonts/hanzipen-tc-regular.otf') format('opentype');
}

@font-face {
  font-family: 'Avenir';
  src: url('../../../../assets/fonts/Avenir-Book.woff') format('woff');
}

@font-face {
  font-family: 'Avenir Roman';
  src: url('../../../../assets/fonts/Avenir-Roman.woff') format('woff');
}


@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../../../../assets/fonts/AvenirNextCyr-Demi.woff') format('woff');
}

@font-face {
  font-family: 'Grandstander ExtraBold';
  src: url('../../../../assets/fonts/Grandstander-ExtraBold.woff') format('woff');
}

* {
  padding: 0;
  margin: 0;
}
.event-details {
    font-family: "Grandstander", sans-serif;
    background-color: rgba(253, 209, 142, 0.5);
  }
  .event-details-info {
    display: flex;
    width: 85%;
    margin: 0 7.5% 0 7.5%;
    justify-content: space-evenly;
    color: #514026;
    line-height: 150%; 
    padding: 1.5% 0 2% 0;
  } 
  .event-info-item {
    display: flex;
    font-family: 'Grandstander ExtraBold';
    justify-content: space-evenly;
    align-items: center;
    margin: 47px 20px 30px 41px;
    font-weight: 800;
    color: rgba(81, 64, 38, 1);
    width: 100%;
    max-width: calc(33% - 0px);
  
  }

  .event-info-item span {
    color: #514026;
    font-family: 'Grandstander ExtraBold';
    font-size: 20px;
    font-style: normal;
    line-height: 150%;
  }
  .event-info-item.third {
    margin-right: 70px;
  }
  .event-icon {
    width: 70px;
    height: 70px;
  }
  @media (max-width: 1024px) {
    .event-info-item.first {
      width: 110%;
    }
  }
@media (max-width: 962px) {
  .event-details-info {
    padding: 0;
  }

  .event-info-item {
    margin: 15px 0 30px 0;
  }
  .event-icon {
    width: 70px;
    height: 100px;
  }
  .event-info-item span {
    font-size: 1rem;
  }
 
}
@media (max-width: 820px){
  .event-details-info{
  padding: 5% 0 4% 0;
  }
}
@media (max-width: 768px) {
  .event-details-info { 
    padding: 0;
  }

  .event-info-item {
    margin: 40px 0;
  }
  
  .event-info-item span {
    font-size: .8rem;
  }
  .event-icon {
    width: 60px;
    height: 80px;
  }
}
@media (max-width: 600px) {
  .event-details-info {
    width: 90%;
    margin: 0 5% 0 5%;
    display: flex;
    flex-direction: column;
  }

  .event-info-item {
    display: flex;
    align-items: center;
    padding: 40px 20px;
    margin: auto;
    margin-left: 10%;
    max-width: 80%;
  }
  .event-info-item.first, .event-info-item.third {
    margin-top:10%;
  }
  .event-info-item span {
    width: 50%;
    font-size: 1.2rem;
  }
  .event-icon { width: 50%;
    width: 90px;
    height: 90px;
    object-fit: contain;
  }

}
@media (max-width: 480px) {

}
@media (max-width: 393px) {

  .event-info-item {
    padding-left: 10px;
  }

  .event-info-item span {
    /* width: 50%; */
    font-size: 1rem;
  }

}