.inputField {
    margin-top: 20px;
    text-align: center;
  }
  
  .label {
    display: block;
    font-family: 'Grandstander SemiBold', sans-serif;
    font-size: 0.9rem;
    background-color: rgba(81, 64, 38, 0.6);
    
    color: #FFFFFF;
    padding: 7% 0 12% 0;
    border-radius: 10px 10px 0 0;
    margin: auto;
    margin-bottom: 2px;
  }
  
  .input {
    text-align: center;
    font-family: 'Grandstander ExtraBold', sans-serif;
    width: 97.5%;
    padding: 10px;
    color: #FFFFFF;
    background-color: #514026;
    border: none;
    border-radius: 0 0 10px 10px;
    font-size: 0.9rem;
  }
  
  .scanButton {
    margin-top: 40px;
    font-family: 'Grandstander Bold', sans-serif;
    background: #514026;
    color: #FFFFFF;
    border: none;
    height: 100px;
    text-transform: uppercase;
    width: 100px;
    padding: 25px;
    border-radius: 50%;
    font-size: 0.9rem;
    cursor: pointer;
    margin-bottom: 25px;
  }
  @media (max-width: 1280px) {
    .input {
      width: 98.3%;;
    }
  }
  @media (max-width: 1024px) {
    .input {
      width: 97.8%;;
    }
  }
  @media (max-width: 912px){
    .input {
      width: 97.7%;
    }
  }
  @media (max-width: 768px) {
    .input {
      width: 97%;
    }
    @media (max-width: 600px){
      .input {
        width: 96%;
      }
    }
  }
  @media (max-width: 480px) {
    .label {
      width: 100%;
      font-size: 14px;
      line-height: 1.41;
    }
    .input {
      width: 94.75%;
      font-size: 20px;
    }
    .scanButton {
      font-size: 16px;
    }
  }
  @media (max-width: 390px) {
    .input {
      width: 94.45%;
    }
  }
  