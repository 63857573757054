.sw-store--pagination-container {
  margin: 70px;
  list-style-type: none;
  display: flex;
  justify-content: center;
}

.pagination-container--item {
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
  color: #000000;
  margin-right: 20px;
}
