@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');


#services-banner-container{
    width: 100%;
    height: 100vh;
    /* border: 3px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
}


#banner-inner-container{
    height: 100%;
    /* border: 1px solid green; */
    background-image: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)), url('../../assets/homepage-banner.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
    max-width: 1400px;
    padding-top: 250px;
}

#banner-text{
    /* border: 1px solid green; */
    width: 50%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#banner-text h1{
    color: white;
    font-size: 70px;
    margin-left: 3em;
    font-family: 'Montserrat', sans-serif;
}

@media only screen and (max-width: 600px){
    #banner-text{
        /* border: 1px solid green; */
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    #banner-text h1{
        color: white;
        font-size: 70px;
        margin-left: 10px;
        font-family: 'Montserrat', sans-serif;
    }
}