@font-face {
  font-family: 'Grandstander ExtraBold';
  src: url('../../../../assets/fonts/Grandstander-ExtraBold.woff') format('woff');
}
* {
  padding: 0;
  margin: 0;
}

.header-container {
  font-family: "Grandstander ExtraBold", sans-serif;
  /* background: radial-gradient(93.83% 65.23% at 50% 50%, rgba(253, 209, 142, 0.50) 59.9%, rgba(163, 98, 24, 0.50) 100%), #FFF; */
  background-color: #fbf4eb;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
.header {
  display: flex;
  width: 85%;
  margin: 0 7.5% 0 7.5%;
    justify-content: space-between;
    align-items: center;
  }
  
.logo {
    align-items: center;
    width: 35%;
    margin: 2% 0 2% 0;
  }

  .logo-image {
    max-height: 50px;
  }

  .logo-image.mobile {
    display: none;
  }

  .header-buttons {
    display: flex;
    align-items: center;
    width: 65%;
    gap: 30px;
    justify-content: flex-end;

  }

  .button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 800;
    text-decoration: underline;
    font-family: "Grandstander ExtraBold", sans-serif;
    color: #514026;
  }
 
  .button.map-btn {
    display: none;
  }
  .buy-tickets-btn {
    font-family: "Grandstander ExtraBold", sans-serif;
    background-color: #514026;
    color: #fff;
    padding: 15px 42.5px 15px 42.5px;
    text-decoration: none;
    border-radius: 50px;
    text-shadow:  
                .5px .5px 0px #FFBD73, 
                -.5px -.5px 0px #FFBD73,
                -.5px .5px 0px #FFBD73,
                .5px -.5px 0px #FFBD73;
  }

  /* .button.map-btn:hover {
    
  } */

  @media (max-width: 962px) {
    .button.direction-btn {
      display: none;
    }
    .button.button.map-btn{
      display: flex;
      font-size: 20px;
      font-weight: 800;
    }
  }
@media (max-width: 768px) {
  .header {
    flex-direction: row;
    align-items: center;
  }

  .header-buttons {
    display: flex;
   justify-content: flex-end;
  }
  .logo-image.desktop{
    max-width: 200px;
  }
  /* .logo-image.mobile {
    display: flex;
  } */
  .buy-tickets-btn {
    padding: 15px 20px;
    font-size: 14px;
    text-shadow:  
    .5px .5px 0px #FFBD73, 
    -.5px -.5px 0px #FFBD73,
    -.5px .5px 0px #FFBD73,
    .5px -.5px 0px #FFBD73;
  }
}

@media (max-width: 600px) {
  .header {
  width: 90%;
  margin: 0 5% 0 5%;
  }
  .logo-image.desktop {
    display: none;
  }
  .logo-image.mobile {
    display: flex;
  }
}

@media (max-width: 420px) {
  .buy-tickets-btn{
  padding: 10px 15px;
  font-size: 12px;
  text-shadow:  
  .5px .5px 0px #FFBD73, 
  -.5px -.5px 0px #FFBD73,
  -.5px .5px 0px #FFBD73,
  .5px -.5px 0px #FFBD73;
  }
}

@media (max-width:370px){
  .header-buttons {
    gap: 10px;
  }
}