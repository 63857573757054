#products-card-container{
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid rgb(128, 109, 0); */
    padding: 50px 0px;
    width: 100vw;
}

#products-card-inner-container{
    max-width: 1100px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax( 300px, 1fr));
    column-gap: 60px;
    row-gap: 30px;
    width: 80%;
    /* align-items: center; */
    justify-content: center;
    /* border: 1px solid rgb(128, 109, 0); */
}
