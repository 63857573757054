@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&family=Montserrat&display=swap');

#footer-container{
    /* width: 100vw; */
    background-color: #111111;
    color: #ffffff;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-family: 'Comfortaa', cursive;
    width: 100vw;
}

#links-container{
    width: 900px;
    /* height: 500px; */
    /* border: 1px solid #fff; */
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 100px 0px 20px 0px;
}

#back-to-top-button{
    border: 1px solid #fff;
    width: 30px;
    height: 500px;
}

#footer-nav-link-container{
    /* border: 1px solid #ffffff; */
    height: 80%;
    /* width: 100%; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

#footer-nav-link-container > div{
    /* border: 1px solid #ffffff; */
    width: 200px;
    height: 200px;
    margin-top: 20px;
}

#footer-nav-link-container > div > ul{
    list-style: none;
    font-size: 14px;
    padding: 0px 0px 40px 0px;
}

#footer-nav-link-container > div > ul> li:first-child{
    font-size: 16px;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
}

#footer-nav-link-container > div > ul > li > a{
    text-decoration: none;
    color: #ffffff;
    font-size: 14px;
}

#footer-nav-link-container > div > ul > li{
    line-height: 40px;
}

#privacy-policy-container{
    border-top: 1px solid #ffffff;
    /* height: 20%; */
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    /* justify-content: cente; */
    margin-top: 50px;
    padding: 20px 10px;
}

#privacy-polict-wrapper{
    /* border: 1px solid green; */
    height: 100%;
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    /* padding: 10px 0px; */
}

#privacy-policy-container > select{
    text-decoration: none;
    color: #ffffff;
    font-size: 14px;
    width: 230px;
    margin-top: 10px;
    /* border: 1px solid green; */
}

#privacy-policy-container > #privacy-polict-wrapper > a{
    text-decoration: none;
    color: #ffffff;
    font-size: 14px;
    width: 230px;
    margin-top: 10px;
}

#privacy-policy-container > select{
    border-radius: 4px;
    height: 40px;
    width: 135px;
    padding: 10px;
    color: #ffffff;
    border: 1.5px solid #ffffff;
    background-color: transparent;
    font-family: 'Montserrat', sans-serif;
}

#right-reserved{
    background-color: #333333;
    width: 100%;
    padding: 20px;
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;

}

@media only screen and (max-width: 600px){
    #footer-nav-link-container{
        padding-bottom: 40px;
    }
    #links-container{
        width: 900px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 0px 0px 20px 0px;
    }
    #privacy-policy-container{
        margin-top: 0px;
        padding: 30px 0px 0px;
        display: flex;
        justify-content: center;
    }
    #footer-nav-link-container > div{
        /* border: 1px solid #ffffff; */
        width: 200px;
        height: max-content;
        margin-top: 40px;
    }

    #footer-nav-link-container > div > ul{
        list-style: none;
        font-size: 14px;
        padding: 0px 0px 0px 0px;
    }

    #privacy-policy-container > select{
    text-decoration: none;
    color: #ffffff;
    font-size: 14px;
    width: 40%;
    margin-top: 30px;
}
#privacy-polict-wrapper{
    /* border: 1px solid green; */
    width: 100%;
    display: inline;
}

#privacy-polict-wrapper > a{
    padding: 15px;
}
}