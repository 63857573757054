#more-about-us-container{
    width: 100vw;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0px;
}

#more-about-us-container > #inner-container{
    /* border: 1px solid green; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#more-about-us-container > #inner-container > div{
    width: 70%;
    max-width: 1100px;
    text-align: center;
    /* border: 1px solid green; */
}

#more-about-us-container > #inner-container > div > h1{
    font-family: 'Montserrat', sans-serif;
    font-size: 48px;
    font-weight: bold;
    margin: 70px 0px 0px;
}

#more-about-us-container > #inner-container > div > p{
    font-family: 'Comfortaa', cursive;
    font-size: 16px;
    line-height: 150%;
    margin: 10px 0px;
}