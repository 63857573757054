@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&family=Montserrat&display=swap');

#subscribe-container{
    /* height: 45vh; */
    background-color: #333333;
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 30px;
    /* width: 100vw; */
}

#subscribe-bold-text{
    height: 50%;
    width: 500px;
    display: flex;
    color: #ffffff;
    align-items: center;
    justify-content: center;
    /* border: 1px solid green; */
    text-align: center;
}

#subscribe-bold-text > h1{
    font-family: 'Montserrat', sans-serif;
    font-size: 48px;
    
}

.subscribe-text{
    width: 500px;
    /* border: 1px solid green; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70%;
}

.subscribe-text > p{
    font-size: 14px;
    color: #ffffff;
    font-family: 'Comfortaa', cursive;
    /* border: 1px solid green; */
    width: 100%;
    line-height: 150%;
    padding: 20px 0px;
}

.subscribe-text > form{
    padding: 20px 0px   ;
    margin: 20px 0px;
    width: 100%;
    /* border: 1px solid green; */
    display: flex;
    justify-content: space-between;
}

.subscribe-text > form > #email{
    padding: 10px 5px;
    border: none;
    background-color: transparent;
    border-bottom: 2px solid #C3C1C1 !important;
    color: #C3C1C1;
    width: 300px;
}

.subscribe-text > form > #email::placeholder{
    font-size: 14px;
}

.subscribe-text > form > #email:focus{
    outline: none;
}

.subscribe-text > form > #submit{
    color: #ffffff;
    border: none;
    background-color: #00AF32;
    border-radius: 2px;
    font-family: 'Comfortaa', cursive;
    width: 150px;
}

@media only screen and (max-width: 600px){
    #subscribe-container{
        padding: 60px 30px;
    }
    .subscribe-text > form{
        padding: 0px   ;
        margin: 0px;
        width: 100%;
    }
    .subscribe-text > form > #email{
        width: 50%;
    }
    .subscribe-text > form > #submit{
        width: 40%;
    }
}