@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");


#contact-us-wrapper{
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid green; */
}
.contact-us-header {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #555555;
  /* border: 1px solid green; */
}

.form-container {
  /* border: 1px solid green; */
  height: max-content;
  margin-bottom: 20px;
  width: max-content;
}

.form-container form{
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
}


.form-container form input,
textarea {
  width: 400px;
  height: 3em;
  padding: 15px;
  margin: 15px 0px;
  border: none;
  color: #111111;
  font-weight: bold;
  background-color: #00af3269;
}

.form-container form input:focus,
textarea:focus {
  outline: none;
  /* border: 1px solid #ffffff; */
  /* box-shadow: 0 0 10px #00af32; */
}

.form-container form input::placeholder,
textarea::placeholder {
  color: rgb(17, 17, 17, 0.5);
  font-family: "Montserrat", sans-serif;
}

#contact-us-text-area {
  height: 150px !important;
}

#contact-us-form-button {
  background-color: #00af32;
  color: white;
  height: 10%;
  width: 30%;
  border: none;
  border-radius: 2px;
  align-self: flex-end;
  /* margin-right: 0px; */
  font-family: "Montserrat", sans-serif;
}

#contact-us-form-button:hover {
  cursor: pointer;
  color: #00af32;
  background-color: #ffffff;
  border: 1px solid #00af32;
}

@media only screen and (max-width: 600px) {
  .contact-us-header {
    height: 20vh;
  }
  .contact-us-header {
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: bold;
    width: 100%;
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #555555;
    /* border: 1px solid green; */
    text-align: center;
  }

  .form-container {
    margin-bottom: 15px;
  }

  .form-container form input,
  textarea {
    width: 300px;
  }

  #contact-us-form-button {
    width: 50%;
    border-radius: 5px;
    align-self: center;
    margin-right: 0px;
  }
}
