#meet-the-team-gallery-container{
    /* border: 1px solid green; */
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0px;
}

#meet-the-team-gallery-inner-container{
    /* border: 1px solid green; */
    width: 100%;
    height: max-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
}

#team-member-card{
    /* border: 1px solid green; */
    width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
    margin: 10px;
    text-align: center;
}

#team-member-card > #name{
    font-size: 24px;
    font-family: 'Montserrat', sans-serif;
    color: #000000;
    font-weight: lighter;
    padding-top: 10px;
}

#team-member-card > #role{
    font-size: 16px;
    margin: 0px;
    padding-top: 10px;
    font-weight: lighter;
    font-family: 'Montserrat', sans-serif;
}

#team-member-card > #name > #firstName{
    font-weight: 900 !important;
}

#team-member-card > #social-media-links{
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid green; */

}

#team-member-card > #social-media-links > i{
    margin: 10px 20px;
}