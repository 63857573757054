* {
    margin: 0;
    padding: 0;
}
.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color:#f2d9b7;
  }
  
  .header { 
    width: 90%;
    margin: auto;
    padding: 7% 0 4% 0;
  }
  
  .icon {
    width: 55px;
    height: 50px;
  }
  .icon img {
    max-width: 100%;
  }
  .invalid {  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    margin: auto;
    width: 116.03px;
    height: 122.74px; 
  }

  .instruction {
    text-align: center;
    font-size: 16px;
    line-height: 150%;
    color: #514026;
    font-family: 'Grandstander SemiBold', sans-serif;
  }
  
  .formSection {
    display: flex; width: 90%;
    flex-direction: column;
    align-items: center; 
    background-color: #514026;
    padding: 20px;
    margin-top: 10%;
    border-radius: 40px 40px 0 0; 
  }

  .formDetails {
    padding: 35px;
    width: 90%;
  }
  .field {
    margin-bottom: 25px;
  }
  .label {
    font-size: 20px;
    font-family: 'Grandstander Medium', sans-serif;
    color: #FFFFFF;
    margin-bottom: 10px;
  }
  .value {
    background-color:#514026;;
  }
.formName {
    line-height: 72.5%;
    font-size: 20px;
    font-family: 'Grandstander ExtraBold', sans-serif;
}


  

  
  