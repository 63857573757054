@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

#top-header-container{
    width: 100vw;
    background-color: #E5E5E5;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #555555;
    font-weight: medium;
    font-size: 13px;
    position: fixed;
    height: 40px;
}

i{
    font-size: 16.13px;
}

#top-nav-inner-container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    /* border: 1px solid green; */
    width: 90%;
    /* height: 100%; */
    max-width: 1400px;
}

#contact-container, #social-media-container{

    display: flex;
    align-items: center;
}

#contact-container{
    display: flex;
    /* border: 1px solid green; */
    width: max-content;
}

#contact-container > a{
    text-decoration: none;
    color: #555555;
}

#contact-container > a div{
    /* width: 35%; */
    padding: 0px 10px 0px 50px;
}

#top-header-phone{
    padding-left: 0px !important;
}

#contact-container > a div > i{
    padding: 0px 10px;
}

#social-media-container{
    display: flex;
    justify-content: flex-end;
    justify-self: flex-end;
    /* padding-right: 25px; */
    /* border: 1px solid green; */
}

#social-media-container > a{
    color: #555555;
    text-decoration: none;
}

#social-media-container > a > i{
    padding: 10px 20px;
}

/* Responsive */

@media only screen and (min-width: 600px){

}

@media only screen and (max-width: 600px) {
    #top-nav-inner-container{
        width: 100%;
        display: none;
    }
    #top-header-container{
        font-size: 0.8750em;
        flex-wrap: wrap;
    }
    i{
        font-size: 2vw;
    }
    #contact-container, #social-media-container{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        /* border: 1px solid green; */
    }
    
    #contact-container{
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 0.1px solid gray;
    }
    
    #contact-container > div{
        width: 50%;
        padding: 0px;
    }

    #social-media-container{
        display: flex;
        justify-content: space-between;
        padding: 0px;
    }
    #social-media-container > i{
        padding: 10px 20px;
    }
  }

