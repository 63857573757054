* {
  margin: 0;
  padding: 0;
}
@font-face {
  font-family: 'Avenir';
  src: url('../../../../assets/fonts/Avenir-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../../../../assets/fonts/AvenirNextCyr-Demi.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Roman';
  src: url('../../../../assets/fonts/Avenir-Roman.woff') format('woff');
}

.footer {
  background-color:rgba(253, 209, 142, 0.5);
  position: relative;
}

/* .footer-absolute{
  background-color:rgba(253, 209, 142, 0.5);
  width: 100%;
  position: absolute;
  bottom: 0px;
} */
.footer-container {
    display: flex;
    width: 50%;
    margin: 0 25% 0 25%;
    flex-wrap: wrap;
}
.copyright {
    margin: 0;
    font-weight: lighter;
    font-family: 'Avenir Next Cyr', sans-serif;
    text-align: center;
    font-size: 14px;
    margin-bottom: 2.5%;
    padding-top: 20px;
  }
.footer-frond {
  position: absolute;
  width: 30%;
  left: 0;
  bottom: 5%;
  mix-blend-mode: multiply;
}
.footer-drum {
  position: absolute;
  width: 14%;
  right: 12%;
  bottom: 0;
}
@media (max-width: 600px) {
  .footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 250px 0 0 0;
    align-items: center;
  }

  .footer-container { 
    color: #000;
    width: 53%;
    text-align: center;
    font-family: Avenir Next Cyr;
    font-size: 16px;
    font-weight: lighter;
    line-height: 175%;
    letter-spacing: 1px;
    margin-bottom: 35%;
  }
 
  .footer img {width: 45%;
    height: auto;
  }

  footer img:nth-of-type(2) {
    width: 100%;
    height: auto;
  }

  .footer-drum {
    bottom: 33%;
    left: 51.3%;
    transform: translate(-50%, -50%);
  }
  .footer-frond{
    bottom: 0;
    left: 0;
    right: 0;
  }

}
  @media (max-width: 393px) {
   .copyright {
    font-size: 10px;
   }
  }
  