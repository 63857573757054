* {
    margin: 0;
    padding: 0;
}
.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color:#f2d9b7; 
  }
  
  .header { 
    width: 90%;
    margin: auto;
    padding: 7% 0 4% 0;
  }
  
  .icon {
    width: 55px;
    height: 50px;
  }
  .icon img {
    max-width: 100%;
  }
  .scanImage {  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    margin: auto;
    width: 80%;
    height: 200px; 
    background-image: url('hand-scan.webp'); 
    background-size: contain; 
    background-position: center;
     background-repeat: no-repeat;
  }

  .instruction {
    text-align: center;
    font-size: 16px;
    line-height: 150%;
    color: #514026;
    font-family: 'Grandstander SemiBold', sans-serif;
  }
  
  .qrSection {
    display: flex;
    flex-direction: column;
    align-items: center; 
    background-color: #514026;
    padding: 20px;
    margin-top: 10%;
    border-radius: 40px 40px 0 0; 
  }
  
  .qrCode {
    margin-top: 40px;
    width: 210px;
    height: 164.21px;
    margin-bottom: 25px;
  }
  
  .description {
    background-color: #FEE8C7;
    font-size: 14px;
    color: #222222;
    text-align: center;
    border-radius: 10px;
    padding: 15px 0 10px 0;
    line-height: 3;
    font-family: 'Grandstander Medium', sans-serif;
  }
  
  .footer {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  
  .openCamera {
    background-color: #D09148;
    font-family: 'Grandstander Bold', sans-serif;
    color: #FFFFFF;
    font-size: 20px;
    padding: 10px 35px;
    border: 1px solid #FEE8C7;
    border-radius: 25px;
    cursor: pointer;
  }
  
  