@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

#services-container{
    background-color: #E5E5E5;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid green; */
    width: 100vw;

}

#services-inner-container{
    /* border: 1px solid green; */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1400px;
}


#services-header{
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    height: 25vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid green; */
}

#services-header > h1{
    color: #555555;
    font-size: 48px;
    font-weight: bold;
}

#services-card-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    /* border: 1px solid green; */
    width: 90%;
}

@media only screen and (max-width: 600px){
    #services-header{
        height: 20vh;
    }
    #services-card-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0px 20px;
    }
}