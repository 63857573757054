* {
  margin: 0;
  padding: 0;
}
.dashboardCover {
    background: #f2d9b7;
  }
  .dashboard {
    width: 90%;
    margin: auto;
  }
  .header {
    padding: 7% 0 4% 0;
  }
  
  .header h1 {
    text-transform: uppercase;
    font-family: 'Grandstander Bold', sans-serif;
    text-align: center;
    font-size: 1.2rem;
    color: #222222;
  }
  
  .welcome {
    margin-top: 20px;
    background: #FFF9F1;
    padding: 15px;
    border-radius: 10px;
  }
  
  .welcome h2 {
    font-family: 'Grandstander Bold', sans-serif;
    font-size: 1rem;
    color: #514026;
  }
  
  .welcome p {
    font-family: 'Grandstander Medium', sans-serif;
    font-size: 0.9rem;
    color: #514026;
  }
  
  .icons {
    display: flex;
    flex-direction: row;
    gap: 115px;
    margin-top: 15px;
  }
  
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: #514026;
    color: #FFF9F1;
    border-radius: 50%;
  }
  
  .icon img {
    max-width: 100%; 
    max-height: auto; 
    object-fit: contain;
  }
  .quickActions {
    margin-top: 30px;
  }
  
  .quickActions h3 {
    line-height: 1.6;
    font-family: 'Montserrat Bold', sans-serif;
    font-size: 1rem;
    color: #000000;
    margin-bottom: 20px;
  }
  
  .actions {
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-top: 15px;
  }
  
  .action {   
    display: flex;
    justify-content: center;
    align-items: center;
    width: 54.69px;
    height: 50px;
    background-color: #514026;
    color: #FFF9F1;
    border-radius: 20%;
    margin-bottom: 10%;
  }
  .action img {
    max-width: 50%; 
    max-height: 50%; 
    object-fit: contain;
  }
  .desc {
    color: #4B484E;
    font-size: 10px;
    line-height: 1.6;
    font-family: 'Montserrat Medium', sans-serif;
  }
  @media (max-width: 480px)  {
    .dashboard {
      width: 90%;
      margin: auto;
    }

    .header {
      padding: 7% 0 4% 0;
    }
    .header h1 {
      font-size: 15px;
    }
    .welcome h2 {
      font-size: 14.54px;
    }
    .welcome p {
      width: 95%;
      font-size: 9.09px;
      line-height: 1.6;
      padding: 10px 0;
    }
    .quickActions {
      margin-top: 10%;
      width: 70%;
    }
    .quickActions h3 {
      font-size: 14px;
    }
  }
  @media (max-width: 390px) {
    .icons {
      gap: 95px;
    }
  }