#product-banner-container{
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    /* border: 1px solid green; */
}

#product-banner-container > #inner-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    margin-top: 150px;
    max-width: 1400px;
    /* border: 1px solid green; */

}


#product-banner-container > #inner-container > img{
    margin: 40px 0px;
}

#product-banner-container > #inner-container > h1{
    color: #555555;
    font-size: 64px;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    margin: 20px 0px 10px;
    text-align: center;
}

#product-banner-container > #inner-container > p{
    font-size: 16px;
    /* width: 450px; */
    line-height: 150%;
    text-align: center
    ;
}