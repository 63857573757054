.page-not-found-container{
    height: 100vh;
    background-image: url("../../assets/background.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 1px solid blue; */
    /* max-width: 1400px; */
}

.bigWrapper{
    /* border: 1px solid blue; */
    width: 100%;
    height: 100%;
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.logoContainer{
    width: 100%;
    height: 13vh;
    max-width: 1400px;
    /* border: 1px solid rgb(255, 255, 255); */
    padding-left: 5rem;
    display: flex;
    align-items: center;
}

.page-not-found-inner-container{
        /* border: 1px solid rgb(255, 255, 255); */
        display: flex;
        flex-direction: column;
        align-items: center;
        /* width: 100%; */
        max-width: 1400px;
        height: 80%;
        position: relative;
        /* justify-content: center; */
}

.page-not-found-inner-container > h1{
    font-family: 'Montserrat', sans-serif;
    font-size: 6rem;
    font-style: normal;
    font-weight: 800;
    line-height: 96px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff; 
}

.page-not-found-inner-container > .errorMessage{
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    width: 20rem;
    padding: 20px;

}

.backToHome{
    font-family: 'Montserrat', sans-serif;
    color: #05551C;
    padding: 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    outline: none;
}

.foooter{
    justify-self: flex-end;
    position: absolute;
    bottom: -20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    /* width: 20rem; */
    /* padding: 20px; */
    margin: 0px;
}