* {
    margin: 0;
    padding: 0;
}
.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color:#f2d9b7;
    padding: 18px;
  }
  
  .header { 
    width: 95%;
    margin: auto;
    padding: 7% 0 4% 0; 
  }

  .icon {
    width: 55px;
    height: 50px;
  }
  .icon img {
    max-width: 100%;
  }

  .header h2 {
    font-size: 16px;
    line-height: 1.6;
    padding-left: 10px;
    color: #514026;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Grandstander Medium', sans-serif;
   
  }

  .tabs {
    display: flex;
    gap: 10px;
  }
  
  .tab {
    background-color: #EBD4B3;
    font-family: 'Grandstander Medium', sans-serif;
    border: none;
    border-radius: 25px;
    color: #4B484E;
    padding: 8px 14px;
    font-size: 12px;
    line-height: 1.1;

  }

  .active {
    background-color: #514026;
    color: #FFFFFF;
    border-color: #514026;
  }
 
  .paymentsList {width: 95%;
    display: flex;
    flex-direction: column; border: 1px soild red;
    gap: 16px;
  }
  
  .paymentItem {
    display: grid;
    grid-template-columns: 40px 1fr auto;
    grid-template-rows: auto auto;
    align-items: center;
    background-color: #f8ebd2;
    padding: 12px;
    border-radius: 10px;
    gap: 25px;
    /* margin-bottom: 20px; */
  }
  
  .paymentItem .paymentIcon {
    grid-row: span 2;
    width: 50px;
    height: 50px;
    background-color: #EBD4B3;
    border-radius: 20%;
    display: flex;
  justify-content: center; 
  align-items: center; 
  }
  .arrow {
    width: 30px;
  height: 30px; 
  }
 
  
  .paymentItem .details {
    grid-row: span 2;
  }
  
  .paymentItem .details .name {
    font-family: 'Grandstander SemiBold', sans-serif;
    margin: 0;
    font-size: 14px;
    color: #514026;
  }
  
  .paymentItem .details .ticketType {
    font-size: 12px;
    font-family: 'Grandstander Medium', sans-serif;
    color: #4F4F4F;
  }
  
  .paymentItem .amount {
    font-family: 'Grandstander Regular', sans-serif;
    color: #514026;
    font-size: 16px;
    text-align: right;
  }
  
  .paymentItem .timestamp {
    font-family: 'Grandstander Regular', sans-serif;
    margin-top: -35px;
    font-size: 12px;
    color: #4F4F4F;
    text-align: right;
  }
  